import React from "react";
import { Bounce } from "react-awesome-reveal";
import Industrial from '../images/insdustrial.jpg';
import Commercial from '../images/commercial.jpg';

const IndustrialCommercialSec = () => {
    return ( 
        <Bounce>  
        <section className="industrial_commercial_sec">
            <div className="container-fluid noPadding">
                <div className="row">
                
                    <div className="col-md-6 noPadding">
                        <div className="industrial_post ">
                        <img src={Industrial} alt="Image"/>
                        <div className="industrial_text industrial_text2 text-start">
                            <h4>Industrial</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod. Lorem ipsum dolor sit amet, consectetur.</p>
                        </div>
                        </div>
                    </div>
                
                <div className="col-md-6 noPadding">
                    <div className="industrial_post">
                    <img src={Commercial} alt="Image"/>
                    <div className="industrial_text text-end">
                        <h4>Commercial</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod. Lorem ipsum dolor sit amet, consectetur.</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </section>
        </Bounce>
     );
}
 
export default IndustrialCommercialSec;