import React from "react";
import logo from '../images/logo.png';
import { Fade } from "react-awesome-reveal";
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";

const Header = () => {
    return ( 
      <Fade>
          <header>
            <div className="topSec">
              <div className="container">
                <div className="row flexRow">
                  <div className="col-md-6 col-sm-6 col-12 ">
                    <div className="logo">
                      <Link to="/"><img src={logo} alt="Logo"/></Link>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-12 ">
                    <ul className="top_mail text-end">
                      <li><Link to="mailto:Jeff.goff@texasmci.com"><FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon> Jeff.goff@texasmci.com</Link></li>
                      
                    </ul>
                  </div>
                
                </div>
              </div>
            </div>
            <div className="menuSec">
              <div className="container">
                <div className="row flexRow">
                  
                  <div className="col-md-7 d-none d-md-block">
                    <ul id="menu">
                      <li><Link to="/">Home</Link></li>
                      <li><Link to="/about">About Us</Link></li>
                      <li><Link to="/services">Services</Link></li>
                      <li><Link to="/contact">Contact Us</Link></li>
                      
                    </ul>
                  </div>
                  <div className="col-md-5 col-12">
                    <div className="menu_phone text-end">
                      <Link to="tel:2149492548"><FontAwesomeIcon icon={faPhone}></FontAwesomeIcon> 2149492548</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>
          </Fade>
     );
}
 
export default Header;