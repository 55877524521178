import React from 'react';
import { Link } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';
import Service1 from '../images/services1.jpg';
import Service2 from '../images/services2.jpg';
import Service3 from '../images/services3.jpg';
import ServIcon1 from '../images/service_icon1.png';
import ServIcon2 from '../images/service_icon2.png';
import ServIcon3 from '../images/service_icon3.png';


const ServicesSec = () => {
    return ( 
        <section className="services_sec pt-8 pb-8">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-8 col-md-10 col-sm-12 col-12 centerCol">
                <Fade>
                  <div className="services_heading text-center">
                    <h2>Services</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore.</p>
                  </div>
                </Fade>
              </div>
            </div>


            <div className="row mt-5">
              <div className="col-md-6 col-lg-4 col-12">
                <Fade delay={150}>
                  <div className="services_post">
                    <div className="service_post_img">
                      <img src={Service1} alt="Image"/>
                    </div>
                    <div className="service_post_content text-center">
                      <div className="service_icon ">
                        <img src={ServIcon1} alt="Icon"/>
                      </div>
                      <h4>Your Heading HEre</h4>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt</p>
                      <Link to="/ServiceDetail">Read More</Link>
                    </div>
                  </div>
                </Fade>
              </div>
              <div className="col-md-6 col-lg-4 col-12">
                <Fade delay={250}>
                  <div className="services_post">
                    <div className="service_post_img">
                      <img src={Service2} alt="Image"/>
                    </div>
                    <div className="service_post_content text-center">
                      <div className="service_icon ">
                        <img src={ServIcon2} alt="Icon"/>
                      </div>
                      <h4>Your Heading HEre</h4>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt</p>
                      <Link to="/ServiceDetail">Read More</Link>
                    </div>
                  </div>
                </Fade>
              </div>
              <div className="col-md-6 col-lg-4 col-12">
                <Fade delay={350}>
                  <div className="services_post">
                    <div className="service_post_img">
                      <img src={Service3} alt="Image"/>
                    </div>
                    <div className="service_post_content text-center">
                      <div className="service_icon ">
                        <img src={ServIcon3} alt="Icon"/>
                      </div>
                      <h4>Your Heading HEre</h4>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt</p>
                      <Link to="/ServiceDetail">Read More</Link>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-6 col-lg-4 col-12">
                <Fade delay={450}>
                  <div className="services_post">
                    <div className="service_post_img">
                      <img src={Service1} alt="Image"/>
                    </div>
                    <div className="service_post_content text-center">
                      <div className="service_icon ">
                        <img src={ServIcon1} alt="Icon"/>
                      </div>
                      <h4>Your Heading HEre</h4>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt</p>
                      <Link to="/ServiceDetail">Read More</Link>
                    </div>
                  </div>
                </Fade>
              </div>
              <div className="col-md-6 col-lg-4 col-12">
                <Fade delay={550}>
                  <div className="services_post">
                    <div className="service_post_img">
                      <img src={Service2} alt="Image"/>
                    </div>
                    <div className="service_post_content text-center">
                      <div className="service_icon ">
                        <img src={ServIcon2} alt="Icon"/>
                      </div>
                      <h4>Your Heading HEre</h4>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt</p>
                      <Link to="/ServiceDetail">Read More</Link>
                    </div>
                  </div>
                </Fade>
              </div>
              <div className="col-md-6 col-lg-4 col-12">
                <Fade delay={650}>
                  <div className="services_post">
                    <div className="service_post_img">
                      <img src={Service3} alt="Image"/>
                    </div>
                    <div className="service_post_content text-center">
                      <div className="service_icon ">
                        <img src={ServIcon3} alt="Icon"/>
                      </div>
                      <h4>Your Heading HEre</h4>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt</p>
                      <Link to="/ServiceDetail">Read More</Link>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </section>
     );
}
 
export default ServicesSec;