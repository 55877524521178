import React from "react";
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Product1 from '../images/product1.jpg';
import Product2 from '../images/product2.jpg';
import Product3 from '../images/product3.jpg';
import Product4 from '../images/product4.jpg';

const ProductsSec = () => {
    return ( 
        <section className="products_sec pt-8 pb-8">
            <div className="container">
                <div className="row">
                <div className="col-xl-6 col-lg-8 col-md-10 col-sm-12 col-12 centerCol">
                    <div className="products_heading text-center">
                    <h2>Products</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore.</p>
                    </div>
                </div>
                </div>


                <div className="row mt-5">
                <div className="col-md-6 col-lg-3 col-sm-6 col-12">
                    <div className="products_post">
                    <img src={Product1} alt="Image"/>
                    <a href="product-detail.html">
                    <div className="product_content">
                        <ul>
                        <li><FontAwesomeIcon icon={faStar}></FontAwesomeIcon></li>
                        <li><FontAwesomeIcon icon={faStar}></FontAwesomeIcon></li>
                        <li><FontAwesomeIcon icon={faStar}></FontAwesomeIcon></li>
                        <li><FontAwesomeIcon icon={faStar}></FontAwesomeIcon></li>
                        <li><FontAwesomeIcon icon={faStar}></FontAwesomeIcon></li>
                        </ul>
                        <h4>Your Heading Here</h4>
                        <p><span> $420.00 </span> $390.00</p>
                    </div>
                    </a>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3 col-sm-6 col-12">
                    <div className="products_post">
                    <img src={Product2} alt="Image"/>
                    <a href="product-detail.html">
                    <div className="product_content">
                        <ul>
                        <li><FontAwesomeIcon icon={faStar}></FontAwesomeIcon></li>
                        <li><FontAwesomeIcon icon={faStar}></FontAwesomeIcon></li>
                        <li><FontAwesomeIcon icon={faStar}></FontAwesomeIcon></li>
                        <li><FontAwesomeIcon icon={faStar}></FontAwesomeIcon></li>
                        <li><FontAwesomeIcon icon={faStar}></FontAwesomeIcon></li>
                        </ul>
                        <h4>Your Heading Here</h4>
                        <p><span> $420.00 </span> $390.00</p>
                    </div>
                    </a>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3 col-sm-6 col-12">
                    <div className="products_post">
                    <img src={Product3} alt="Image"/>
                    <a href="product-detail.html">
                    <div className="product_content">
                        <ul>
                        <li><FontAwesomeIcon icon={faStar}></FontAwesomeIcon></li>
                        <li><FontAwesomeIcon icon={faStar}></FontAwesomeIcon></li>
                        <li><FontAwesomeIcon icon={faStar}></FontAwesomeIcon></li>
                        <li><FontAwesomeIcon icon={faStar}></FontAwesomeIcon></li>
                        <li><FontAwesomeIcon icon={faStar}></FontAwesomeIcon></li>
                        </ul>
                        <h4>Your Heading Here</h4>
                        <p><span> $420.00 </span> $390.00</p>
                    </div>
                    </a>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3 col-sm-6 col-12">
                    <div className="products_post">
                    <img src={Product4} alt="Image"/>
                    <a href="product-detail.html">
                    <div className="product_content">
                        <ul>
                        <li><FontAwesomeIcon icon={faStar}></FontAwesomeIcon></li>
                        <li><FontAwesomeIcon icon={faStar}></FontAwesomeIcon></li>
                        <li><FontAwesomeIcon icon={faStar}></FontAwesomeIcon></li>
                        <li><FontAwesomeIcon icon={faStar}></FontAwesomeIcon></li>
                        <li><FontAwesomeIcon icon={faStar}></FontAwesomeIcon></li>
                        </ul>
                        <h4>Your Heading Here</h4>
                        <p><span> $420.00 </span> $390.00</p>
                    </div>
                    </a>
                    </div>
                </div>

                </div>
            </div>
            </section>
     );
}
 
export default ProductsSec;