import React from "react";
import Slider from '../slider';
import AfterSlider from '../serices-list';
import AboutSec from '../about-sec';
import IndustrialCommercialSec from '../industrial-commercial-sec';
import ServicesSec from '../services-sec';
import ProductsSec from '../productsSec';
import ContactSec from '../contactSec';



const Home = () => {
    return ( 
        <div className="homePage">
            
            <Slider/>
            <AfterSlider/>
            <AboutSec/>
            <IndustrialCommercialSec/>
            <ServicesSec/>
            <ProductsSec/>
            <ContactSec/>
            
        </div>    
       
     );
}
 
export default Home;