import React from 'react';
import { Link } from 'react-router-dom';
import FootLogo from '../images/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPhone} from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faWhatsapp, faFacebookF, faInstagram, faYoutube} from '@fortawesome/free-brands-svg-icons';



const Footer = () => {
    return ( 
        <section className='main_footer'>
            <footer className="main_footer pt-8 pb-8">
     <div className="container">
       <div className="row">
         <div className="col-md-6 col-lg-3">
           <div className="footer_about">
             <Link to="/"><img src={FootLogo} alt="Logo"/></Link>
             <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
           </div>
         </div>

          <div className="col-md-6 col-lg-3">
            <div className="services_link">
              <h3>Services</h3>
              <ul>
                <li><Link to="">Lorem Ipsum</Link></li>
                <li><Link to="">Lorem Ipsum</Link></li>
                <li><Link to="">Lorem Ipsum</Link></li>
                <li><Link to="">Lorem Ipsum</Link></li>
                <li><Link to="">Lorem Ipsum</Link></li>
              </ul>
            </div>
          </div>

          <div className="col-md-6 col-lg-3">
            <div className="services_link">
              <h3>Quick Links</h3>
              <ul>
                <li><Link to="">Lorem Ipsum</Link></li>
                <li><Link to="">Lorem Ipsum</Link></li>
                <li><Link to="">Lorem Ipsum</Link></li>
                <li><Link to="">Lorem Ipsum</Link></li>
                <li><Link to="">Lorem Ipsum</Link></li>
              </ul>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="services_link services_link_footer">
              <h3>Contact</h3>
              <ul>
                <li><Link to=""><FontAwesomeIcon icon={faPhone}></FontAwesomeIcon> 2149492548</Link></li>
                <li><Link to=""><FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon> Jeff.goff@texasmci.com</Link></li>
                <li><Link to=""><FontAwesomeIcon icon={faMapMarkerAlt}></FontAwesomeIcon> 215 Dahl</Link></li>
              </ul>
            </div>
          </div>
       </div>
     </div>
   </footer>


   <div className="footer_bottom">
     <div className="container">
       <div className="row">
         <div className="col-md-6 col-sm-12">
           <div className="copy_right">
             <p>All Rights Reserved Lorem Ipsum 2021</p>
           </div>
         </div>
         <div className="col-md-6 col-sm-12">
           <div className="copy_right">
             <ul className="text-end">
              <li><Link to=""><FontAwesomeIcon icon={faLinkedin}></FontAwesomeIcon></Link></li>
              <li><Link to=""><FontAwesomeIcon icon={faFacebookF}></FontAwesomeIcon></Link></li>
              <li><Link to=""><FontAwesomeIcon icon={faWhatsapp}></FontAwesomeIcon></Link></li>
              <li><Link to=""><FontAwesomeIcon icon={faInstagram}></FontAwesomeIcon></Link></li>
              <li><Link to=""><FontAwesomeIcon icon={faYoutube}></FontAwesomeIcon></Link></li>
             </ul>
           </div>
         </div>
       </div>
     </div>
   </div>
        </section>
     );
}
 
export default Footer;