import React from "react";
import { Zoom } from "react-awesome-reveal";
import {faPhoneAlt} from '@fortawesome/free-solid-svg-icons';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Time from '../images/clientimg.png';
import Quote from '../images/quote.png';


const ContactSec = () => {
    return ( 
        <Zoom>
            <section className="contact_home_Sec pb-8">
                <div className="container">
                    <div className="row flexRow">
                    <div className="col-md-12 col-lg-6 noRight">
                        <div className="testimonials_sec">
                        <h5>Testimonials</h5>
                        <h2>Clients Say</h2>

                        <div className="slider_testimonial">
                            <div className="col-12">
                            <div className="testimonial_post">
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore. magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit.</p>

                                <div className="client_detail">
                                <div className="client_img">
                                    <img src={Time} alt="Image"/>
                                </div>

                                <div className="client_name">
                                    <h2>Jane Doe</h2>
                                    <p>Project Manager</p>
                                </div>
                                <img className="quote" src={Quote} alt="Icon"/>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="col-md-12 col-lg-6 noLeft">
                        <div className="contact_info_homepage">
                        <div className="contact_heading_home">
                            <h3>Contact Us</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt.</p>


                        </div>

                        <ul>
                            <li><a href=""><FontAwesomeIcon icon={faPhoneAlt}></FontAwesomeIcon> 2149492548</a></li>
                            <li><a href=""><FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon> Jeff.goff@texasmci.com</a></li>
                            <li><a href=""><FontAwesomeIcon icon={faMapMarkerAlt}></FontAwesomeIcon> 215 Dahl</a></li>
                        </ul>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        </Zoom>
     );
}
 
export default ContactSec;