import React from "react";
import {Link} from 'react-router-dom';
import { Zoom } from "react-awesome-reveal";
import About1 from '../images/aboutimg_1.jpg';
import About2 from '../images/aboutimg_2.jpg';


const AboutSec = () => {
    return ( 
      <Zoom>
          <section className="about_sec pt-8 pb-8">
            <div className="container">
              <div className="row flexRow">
                <div className="col-md-6">
                  <div className="about_img">
                    <div className="about_images">
                      <img className="about_image1 img-responsive" src={About1} alt="Image"/>
                      <img className="about_image2 img-responsive" src={About2} alt="Image"/>
                    </div>
                    <div className="video-overlay-sec">

              
                          <Link id="play-video" className="video-launch video-play-button" to="" data-fancybox="">

                          <span></span>
                          </Link> 
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="about_content_sec">
                    <h5>About US</h5>
                    <h2>Building Trust Through metal</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore. magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                    <p> Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi.</p>
                    <Link to="/about" className="btn10">Learn More</Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
      </Zoom>
    );
  
}
 
export default AboutSec;