import React from 'react';
import InnerBanner from '../../images/inner-banner.jpg';
import About1 from '../../images/aboutimg_1.jpg';
import About2 from '../../images/aboutimg_2.jpg';


const AboutPage = () => {
    return ( 
        <main className='aboutPage'>

        
            <section className="inner-banner">
                <img src={InnerBanner} alt="Image"/>
                <div className="banner_text">
                    <div className="container">
                    <div className="row">
                        <h2>About Us</h2>
                    </div>
                    </div>
                </div>
            </section>
            <section className="about_sec pt-8 pb-8">
                <div className="container">
                    <div className="row flexRow">
                    <div className="col-md-6">
                        <div className="about_img">
                        <div className="about_images">
                            <img className="about_image1 img-responsive" src={About1} alt="Image"/>
                            <img className="about_image2 img-responsive" src={About2} alt="Image"/>
                        </div>
                        <div className="video-overlay-sec">

                            
                                <a id="play-video" className="video-launch video-play-button" href="video/A-Life-Changing-Therapy-For-Children-With-Autism-At-The-Child-Study-Center.mp4" data-fancybox="">

                                <span></span>
                                </a> 
                        </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="about_content_sec">
                        <h5>About US</h5>
                        <h2>Building Trust Through metal</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore. magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                        <p> Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi. architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem. sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore. magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                        </div>
                    </div>
                    </div>

                    <div className="row mt-3">
                    <div className="col-md-12 col-12">
                        <div className="about_content_sec">
                        
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore. magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                        <p> Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi. architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem. sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>
                        </div>
                    </div>
                    </div>
                </div>
                </section>          
        </main>
     );
}
 
export default AboutPage;