import React from 'react';
import InnerBanner from '../../images/inner-banner.jpg';
import {Link} from 'react-router-dom';
import Service1 from '../../images/services1.jpg';
import Service2 from '../../images/services2.jpg';
import Service3 from '../../images/services3.jpg';
import ServIcon1 from '../../images/service_icon1.png';
import ServIcon2 from '../../images/service_icon2.png';
import ServIcon3 from '../../images/service_icon3.png';


const ServicePage = () => {
    return ( 
        <main className='ServicePage'>
            <section className="inner-banner">
                <img src={InnerBanner} alt="Image"/>
                <div className="banner_text">
                    <div className="container">
                    <div className="row">
                        <h2>Services</h2>
                    </div>
                    </div>
                </div>
            </section>
            <section className="services_sec pt-8 pb-8">
                <div className="container">
                    


                    <div className="row mt-5">
                    <div className="col-md-6 col-lg-4 col-12">
                        <div className="services_post">
                        <div className="service_post_img">
                            <img src={Service1} alt="Image"/>
                        </div>
                        <div className="service_post_content text-center">
                            <div className="service_icon ">
                            <img src={ServIcon1} alt="Icon"/>
                            </div>
                            <h4>Your Heading HEre</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt</p>
                            <Link to="/ServiceDetail">Read More</Link>
                        </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 col-12">
                        <div className="services_post">
                        <div className="service_post_img">
                            <img src={Service2} alt="Image"/>
                        </div>
                        <div className="service_post_content text-center">
                            <div className="service_icon ">
                            <img src={ServIcon2} alt="Icon"/>
                            </div>
                            <h4>Your Heading HEre</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt</p>
                            <Link to="/ServiceDetail">Read More</Link>
                        </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 col-12">
                        <div className="services_post">
                        <div className="service_post_img">
                            <img src={Service3} alt="Image"/>
                        </div>
                        <div className="service_post_content text-center">
                            <div className="service_icon ">
                            <img src={ServIcon3} alt="Icon"/>
                            </div>
                            <h4>Your Heading HEre</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt</p>
                            <Link to="/ServiceDetail">Read More</Link>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="row mt-5">
                    <div className="col-md-6 col-lg-4 col-12">
                        <div className="services_post">
                        <div className="service_post_img">
                            <img src={Service1} alt="Image"/>
                        </div>
                        <div className="service_post_content text-center">
                            <div className="service_icon ">
                            <img src={ServIcon1} alt="Icon"/>
                            </div>
                            <h4>Your Heading HEre</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt</p>
                            <Link to="/ServiceDetail">Read More</Link>
                        </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 col-12">
                        <div className="services_post">
                        <div className="service_post_img">
                            <img src={Service2} alt="Image"/>
                        </div>
                        <div className="service_post_content text-center">
                            <div className="service_icon ">
                            <img src={ServIcon2} alt="Icon"/>
                            </div>
                            <h4>Your Heading HEre</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt</p>
                            <Link to="/ServiceDetail">Read More</Link>
                        </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 col-12">
                        <div className="services_post">
                        <div className="service_post_img">
                            <img src={Service3} alt="Image"/>
                        </div>
                        <div className="service_post_content text-center">
                            <div className="service_icon ">
                            <img src={ServIcon3} alt="Icon"/>
                            </div>
                            <h4>Your Heading HEre</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt</p>
                            <Link to="/ServiceDetail">Read More</Link>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="row mt-5">
                    <div className="col-md-6 col-lg-4 col-12">
                        <div className="services_post">
                        <div className="service_post_img">
                            <img src={Service1} alt="Image"/>
                        </div>
                        <div className="service_post_content text-center">
                            <div className="service_icon ">
                            <img src={ServIcon1} alt="Icon"/>
                            </div>
                            <h4>Your Heading HEre</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt</p>
                            <Link to="/ServiceDetail">Read More</Link>
                        </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 col-12">
                        <div className="services_post">
                        <div className="service_post_img">
                            <img src={Service2} alt="Image"/>
                        </div>
                        <div className="service_post_content text-center">
                            <div className="service_icon ">
                            <img src={ServIcon2} alt="Icon"/>
                            </div>
                            <h4>Your Heading HEre</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt</p>
                            <Link to="/ServiceDetail">Read More</Link>
                        </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 col-12">
                        <div className="services_post">
                        <div className="service_post_img">
                            <img src={Service3} alt="Image"/>
                        </div>
                        <div className="service_post_content text-center">
                            <div className="service_icon ">
                            <img src={ServIcon3} alt="Icon"/>
                            </div>
                            <h4>Your Heading HEre</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt</p>
                            <Link to="/ServiceDetail">Read More</Link>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="row mt-5">
                    <div className="col-md-6 col-lg-4 col-12">
                        <div className="services_post">
                        <div className="service_post_img">
                            <img src={Service1} alt="Image"/>
                        </div>
                        <div className="service_post_content text-center">
                            <div className="service_icon ">
                            <img src={ServIcon1} alt="Icon"/>
                            </div>
                            <h4>Your Heading HEre</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt</p>
                            <Link to="/ServiceDetail">Read More</Link>
                        </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 col-12">
                        <div className="services_post">
                        <div className="service_post_img">
                            <img src={Service2} alt="Image"/>
                        </div>
                        <div className="service_post_content text-center">
                            <div className="service_icon ">
                            <img src={ServIcon2} alt="Icon"/>
                            </div>
                            <h4>Your Heading HEre</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt</p>
                            <Link to="/ServiceDetail">Read More</Link>
                        </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 col-12">
                        <div className="services_post">
                        <div className="service_post_img">
                            <img src={Service3} alt="Image"/>
                        </div>
                        <div className="service_post_content text-center">
                            <div className="service_icon ">
                            <img src={ServIcon3} alt="Icon"/>
                            </div>
                            <h4>Your Heading HEre</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt</p>
                            <Link to="/ServiceDetail">Read More</Link>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </section>
        </main>
     );
}
 
export default ServicePage;