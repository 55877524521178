import React from "react";
import {Link} from 'react-router-dom';
import InnerBanner from '../../images/inner-banner.jpg';
import Contact1 from '../../images/contact-icn-1.png';
import Contact2 from '../../images/contact-icn-2.png';
import Contact3 from '../../images/contact-icn-3.png';

const ContactPage = () => {
    return ( 
        <main className="ContactPage">
            <section className="inner-banner">
                <img src={InnerBanner} alt="Image"/>
                <div className="banner_text">
                    <div className="container">
                    <div className="row">
                        <h2>Contact Us</h2>
                    </div>
                    </div>
                </div>
            </section>
            <section className="contact_page pt-8 pb-8">
                <div className="container">
                    

            

                    <div className="row mt-5">
                    <div className="col-12 col-sm-12 col-lg-7">
                        <div className="contact_map">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d2837.9243719524243!2d-93.12395378425285!3d44.659896794768336!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2s!4v1633521350411!5m2!1sen!2s"></iframe>
                        </div>
                    </div>


                    <div className="col-12 col-sm-12 col-lg-5">
                        <div className="contact_form">
                        <h3>Your Details</h3>
                        <form>
                            <div className="row">
                            <div className="col-sm-6 col-12">
                                <input type="text" name="fname" placeholder="Your Name"/>
                            </div>
                            <div className="col-sm-6 col-12">
                                <input type="text" name="phone" placeholder="Your Phone"/>
                            </div>
                            <div className="col-sm-12 col-12">
                                <input type="text" name="email" placeholder="Your Email"/>
                            </div>
                            <div className="col-sm-12 col-12">
                                <textarea rows="7" placeholder="Your Message"></textarea>
                            </div>
                            <div className="col-sm-12 col-12">
                                <button type="submit" className="btn10">Send Message</button>
                            </div>
                            </div>
                        </form>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-12 col-sm-12 col-md-4">
                        <div className="contact_detail_icons text-center">
                            <img src={Contact1} alt="ContactIcon"/>
                            <h5>Telephone</h5>
                            <p><a href="tel:9403046285">9403046285</a></p>
                        </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-4">
                        <div className="contact_detail_icons text-center">
                            <img src={Contact2} alt="ContactIcon"/>
                            <h5>Address</h5>
                            <p><a href="">Your Address Here</a></p>
                        </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-4">
                        <div className="contact_detail_icons text-center">
                            <img src={Contact3} alt="ContactIcon"/>
                            <h5>E Mail</h5>
                            <p><a href="mailto:940rollofs@gmail.com">940rollofs@gmail.com</a></p>
                        </div>
                        </div>
                    </div>

                    </div>
                </div>
                </section>
        </main>
     );
}
 
export default ContactPage;