import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Header from './components/header';
import Home from './components/home/home';
import AboutPage from './components/about/about-page';
import ServicePage from './components/services/ServicePage';
import ContactPage from './components/contact/contactPage';
import ServiceDetail from './components/services/service-detail';
import Footer from './components/footer';
import './App.css';



function App() {
  return (
    <Router>
    <div className="App">
      <Header/>
      <Routes>
        <Route path="/" element={ <Home/> }></Route>
        <Route path='/about' element={ <AboutPage/> } ></Route>
        <Route path='/services' element={ <ServicePage /> } ></Route>
        <Route path='/contact'  element={ <ContactPage /> } ></Route>
        <Route path='/ServiceDetail'  element={ <ServiceDetail /> } ></Route>
      </Routes>
      <Footer/>
    </div>
    </Router>
  );
}

export default App;
