import React from "react";
// import { Carousel } from "react-bootstrap";
import {Link} from 'react-router-dom';
import Banner from '../images/banner.jpg';
import { Carousel } from "react-bootstrap";

const Slider = () => {
    return ( 
       <section className="main_slider">
            <Carousel>
                <Carousel.Item>
                    <img src={Banner} className=" main_banner_img" alt="..."/>
                    <Carousel.Caption>
                    <div className="container">
                        <div className="row">
                        <div className="col-xs-12 col-sm-6 col-md-6">
                            <div className="banner_txt wow fadeInLeft" data-wow-duration="2s">
                            <h1>Creating Brand New Possibilities</h1>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore. </p>
                            <Link to="/about" className="btn10">Learn More</Link>
                            </div>
                        </div>
                        
                        </div>
                    </div>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={Banner} className=" main_banner_img" alt="..."/>

                    <Carousel.Caption>
                    <div className="container">
                        <div className="row">
                        <div className="col-xs-12 col-sm-6 col-md-6">
                            <div className="banner_txt wow fadeInLeft" data-wow-duration="2s">
                            <h1>Creating Brand New Possibilities</h1>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore. </p>
                            <Link to="/about" className="btn10">Learn More</Link>
                            </div>
                        </div>
                        
                        </div>
                    </div>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={Banner} className=" main_banner_img" alt="..."/>

                    <Carousel.Caption>
                    <div className="container">
                        <div className="row">
                        <div className="col-xs-12 col-sm-6 col-md-6">
                            <div className="banner_txt wow fadeInLeft" data-wow-duration="2s">
                            <h1>Creating Brand New Possibilities</h1>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore. </p>
                            <Link to="/about" className="btn10">Learn More</Link>
                            </div>
                        </div>
                        
                        </div>
                    </div>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </section>

        
     );
}
 
export default Slider;