import React from "react";
import { Fade } from "react-awesome-reveal";
import Maintain from '../images/maintanance.png';
import Enginner from '../images/engineering.png';
import inspection from '../images/inspections.png';

const AfterSlider = () => {
    return ( 
        <section className="after_main_banner">
            <div className="container">
                <div className="row">
                <div className="col-md-4 col-12">
                    <Fade delay={200}>
                        <div className="categories_post text-center">
                            <div className="category_icon">
                                <img src={Maintain} alt="Icon"/>
                            </div>
                            <div className="category_name text-center">
                                <h4>Maintainance</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                        </div>
                    </Fade>    
                </div>
                <div className="col-md-4 col-12">
                    <Fade delay={600}>
                        <div className="categories_post text-center">
                            <div className="category_icon">
                                <img src={Enginner} alt="Icon"/>
                            </div>
                            <div className="category_name text-center">
                                <h4>Engineering</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                        </div>
                    </Fade>
                </div>
                <div className="col-md-4 col-12">
                    <Fade delay={1000}>
                        <div className="categories_post text-center">
                            <div className="category_icon">
                                <img src={inspection} alt="Icon"/>
                            </div>
                            <div className="category_name text-center">
                                <h4>Inspections</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                        </div>
                    </Fade>
                </div>
                </div>
            </div>
            </section>
     );
}
 
export default AfterSlider;